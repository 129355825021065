/* eslint-disable */
import { base, baseFile} from '@/utils/api'
  
const ENDPOINT = '/sus'

export default {
    getInformationSusSettingByClinicId(clinicId) {
        return base.get(`${ENDPOINT}/information/${clinicId}/getByClinicId`);
    },
    getInformationSusSettingByHealthPlanId(healthPlanId) {
        return base.get(`${ENDPOINT}/information/${healthPlanId}/getByHealthPlanId`);
    },
      
    printSusGuide(guideId, userName) {
        return baseFile.get(`${ENDPOINT}/printGuide/${guideId}`, {params: {userName}})
    },

    getSusGuides(clinicId, page, data) {
      const config = {
        params: { data }
      }
      return base.get(`${ENDPOINT}/guide/clinic/${clinicId}/getByClinicId?page=${page}`, config);
    },
    getSusGuideIds(clinicId, data) {
        const config = {
          params: { data }
        }
        return base.get(`${ENDPOINT}/guide/clinic/${clinicId}/getAllGuideIds`, config);
      },
    getSusGuidesForAutomaticBatch(clinicId, type) {
        return base.get(`${ENDPOINT}/guide/${clinicId}/${type}/getForAutomaticBatch`);
    },
    getSusGuidesByBatchId(batchId, page, data) {
        const config = {
          params: { data }
        }
        return base.get(`${ENDPOINT}/guide/${batchId}/getByBatchId?page=${page}`, config);
    },
    getSusGuide(id) {
        return base.get(`${ENDPOINT}/guide/${id}`);
    },
    createSusGuide(data) {
        return base.post(`${ENDPOINT}/guide`, data);
    },
    updateBatchIdManyGuides(data) {
        return base.post(`${ENDPOINT}/guide/updateBatchIdManyGuides`, data);
    },
    updateSusGuide(id, data) {
        return base.put(`${ENDPOINT}/guide/${id}`, data);
    },
    deleteSusGuide(id) {
        return base.delete(`${ENDPOINT}/guide/${id}`);
    },

    getSusBatches(clinicId, page, data) {
        const config = {
            params: { data }
        }
        return base.get(`${ENDPOINT}/batch/${clinicId}/getByClinicId?page=${page}`, config);
    },
    getSusBatchesOptions(clinicId, type) {
        return base.get(`${ENDPOINT}/batch/${clinicId}/${type}/getOptions`);
    },
    getSusBatch(id) {
        return base.get(`${ENDPOINT}/batch/${id}/findById`);
    },
    createAutomaticSusBatches(data) {
        return base.post(`${ENDPOINT}/batch/createAutomatic`, data);
    },
    createSusBatch(data) {
        return base.post(`${ENDPOINT}/batch`, data);
    },
    updateSusBatch(id, data) {
        return base.put(`${ENDPOINT}/batch/${id}`, data);
    },
    updateManySusBatches(data) {
        return base.put(`updateManySusBatches`, data);
    },
    deleteSusBatch(id) {
        return base.delete(`${ENDPOINT}/batch/${id}`);
    },
    getSusGuideRequiredFields(clinicId) {
        return base.get(`sus-guides/clinic/${clinicId}/required-fields`);
    },
}